import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Grid, {
  shouldForwardProp: prop => !['wideTextBlock'].includes(prop),
})(({ theme, wideTextBlock }) => ({
  '& *': {
    ...(!wideTextBlock && { color: theme.palette.text.secondary }),
  },
  // Headings
  h1: {
    ...(wideTextBlock
      ? theme.typography.h1Redesign
      : theme.typography.h5Redesign),
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  h2: {
    ...(wideTextBlock
      ? theme.typography.h2Redesign
      : theme.typography.h5Redesign),
    overflowWrap: 'break-word',
  },
  h3: {
    ...(wideTextBlock
      ? theme.typography.h3Redesign
      : theme.typography.h6Redesign),
    breakAfter: 'avoid',
    pageBreakAfter: 'avoid',
  },
  h4: wideTextBlock ? theme.typography.h4Redesign : theme.typography.h6Redesign,
  h5: wideTextBlock ? theme.typography.h5Redesign : theme.typography.h6Redesign,
  h6: theme.typography.h6Redesign,

  'h1, h2, h3': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  'h1 strong, h2 strong, h3 strong, h4 strong': {
    fontWeight: 'inherit',
  },

  // Paragraphs and lists
  '& p, & ul, & ol': {
    ...theme.typography.body1Redesign,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  '& p:has(> img)': {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  '.ck-content figure': {
    marginTop: `${theme.spacing(6)}`,
    marginBottom: `${theme.spacing(6)}`,
  },
  p: { breakInside: 'avoid' },
  ul: {
    paddingLeft: 0,
    listStyle: 'none',
  },
  ol: {
    counterReset: 'item',
    paddingInlineStart: 0,
  },
  '& ul li, & ol li': {
    paddingLeft: theme.spacing(3.5),
    position: 'relative',
  },
  '& ul li:before, & ol li:before': {
    position: 'absolute',
    fontWeight: 'bold',
    top: 0,
    left: 0,
  },
  '& ol li': {
    display: 'block',
  },
  '& ol li:before': { content: "counter(item) '. '", counterIncrement: 'item' },
  '& ul li:before': {
    content: '"• "',
  },
  '& li + li': {
    marginTop: theme.spacing(1),
  },
  a: {
    fontStyle: 'normal',
    textDecoration: 'underline',
    wordBreak: 'break-word',
  },
  img: {
    maxWidth: '100%',
    borderRadius: 15,
  },

  // Embedded content
  '& [data-oembed][data-oembed-provider="YouTube"]': {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '50%',
  },
  '& [data-oembed] iframe': {
    width: '100%',
  },
  '& [data-oembed][data-oembed-provider="YouTube"] iframe': {
    position: 'absolute',
    minWidth: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },

  // Blockquotes
  blockquote: {
    position: 'relative',
    padding: 0,
    border: 'none',
    paddingLeft: theme.spacing(9),
    margin: `${theme.spacing(6)} 0`,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(7),
    },

    '& *': {
      ...theme.typography.h5Redesign,
      fontStyle: 'normal',
      color: theme.palette.text.secondary,
    },

    '& *:first-child': {
      marginTop: 0,
    },

    '& *:last-child': {
      marginBottom: 0,
    },

    '&:before': {
      content: '""',
      backgroundImage:
        "url('data:image/svg+xml,%3Csvg width=%2728%27 height=%2724%27 viewBox=%270 0 28 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M12.528 12.616V23.816H0.624V13.256L6.448 0.967999H12.528L7.984 12.616H12.528ZM27.184 12.616V23.816H15.28V13.256L21.104 0.967999H27.184L22.64 12.616H27.184Z%27 fill=%27%230171B7%27/%3E%3C/svg%3E%0A')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left',
      display: 'block',
      width: 28,
      height: 24,
      position: 'absolute',
      left: 0,
      top: 5,
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& p.block-img, & [data-oembed] iframe': {
      width: 'calc(100% + 4.5rem)',
      marginLeft: `-${theme.pageMarginHorizontalMobile}`,
    },
    '& [data-oembed][data-oembed-provider="YouTube"]': {
      paddingBottom: '70%',
    },
    '& [data-oembed][data-oembed-provider="YouTube"] iframe': {
      marginLeft: 0,
      left: `-${theme.pageMarginHorizontalMobile}`,
      width: 'calc(100% + 4.5rem)',
    },
  },
}));

export const TitleGrid = styled(Grid)(({ theme }) => ({
  '.ck-content > :first-child': { marginTop: 0 },

  [theme.breakpoints.down('md')]: {
    '.ck-content :last-child': {
      marginBottom: 0,
    },
  },
}));

export const Section = styled('div', {
  shouldForwardProp: prop =>
    !['wideTextBlock', 'sectionsLength', 'index', 'withTitle'].includes(prop),
})(({ theme, wideTextBlock, index, sectionsLength, withTitle }) => ({
  ...(wideTextBlock || !withTitle
    ? {
        '.ck-content > :first-child': { marginTop: 0 },
      }
    : {
        [theme.breakpoints.up('md')]: {
          '.ck-content > :first-child': { marginTop: 0 },
        },
      }),

  ...(index === sectionsLength - 1 && {
    '.ck-content > :last-child': { marginBottom: 0 },
  }),
}));
